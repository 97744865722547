@import '~antd/dist/antd.css';
@import './styles/colors.scss';

:root {
  [data-color-theme='green'] {
    --color-primary: #{$mint-green};
    --color-primary-light: #{$mint-green-dark};
    --color-primary-dark: #{$mint-green-light};
    --color-primary-bg: #{$turqoise};
    --color-primary-bg-dark: #{$turqoise-dark};

    --color-border: #ccc;
  }

  [data-color-theme='orange'] {
    --color-primary: #{$orange};
    --color-primary-light: #{$orange-light};
    --color-primary-dark: #{$orange-dark};
    --color-primary-bg: #{$orange};
    --color-primary-bg-dark: #{$toasted-orange};

    --color-border: #ccc;
  }

  [data-color-theme='pink'] {
    --color-primary: #{$pink};
    --color-primary-light: #{$pink-light};
    --color-primary-dark: #{$pink-dark};
    --color-primary-bg: #{$pink};
    --color-primary-bg-dark: #{$lipstick-pink};

    --color-border: #ccc;
  }

  [data-color-theme='blue'] {
    --color-primary: #{$blue};
    --color-primary-light: #{$blue-light};
    --color-primary-dark: #{$blue-dark};
    --color-primary-bg: #{$cyan};
    --color-primary-bg-dark: #{$ocean};

    --color-border: #ccc;
  }
}

.App {
  text-align: left;
  background-color: white;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.ant-page-header {
  padding-left: 0px;
  padding-top: 0px;
}

.anticon {
  vertical-align: middle;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--color-primary) !important;
    border-color: var(--color-primary-light) !important;
  }
}

.ant-btn-primary {
  border-color: var(--color-primary-dark) !important;
  background-color: var(--color-primary-light) !important;

  &:hover {
    border-color: var(--color-primary) !important;
    background-color: white !important;
    color: var(--color-primary) !important;
    text-shadow: unset !important;
  }
}

// Modals are rendered outside of <App />
// and don't respect data-color-theme
// TODO: Fix this
.ant-modal {
  .ant-btn-primary {
    border-color: $mint-green-dark !important;
    background-color: white !important;
    color: $mint-green-light !important;
    text-shadow: unset !important;

    &:hover {
      border-color: var(--color-primary) !important;
      background-color: white !important;
      color: var(--color-primary) !important;
      text-shadow: unset !important;
    }
  }
}

.ant-avatar {
  background-color: var(--color-primary);
}

.width-80 {
  width: 80%;
}

.flex {
  display: flex;
  align-items: center;
}

.section-title {
  vertical-align: center;
  width: 100%;
  border-radius: 6px;
  padding: 10px 10px;
  background: linear-gradient(90deg, var(--color-primary) 0%, white 200%);

  h2 {
    font-size: x-large;
    margin-bottom: 0;
    color: white;
  }
}
