@import '../../styles/colors.scss';

.frequency-chart {
  path.recharts-curve.recharts-line-curve {
    stroke: var(--color-primary);
  }

  .ant-card {
    margin-top: 5px;

    .card-title {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .chart {
    display: block;
    line-height: 0;
  }

  .stats {
    margin-left: 10px;
  }
}
