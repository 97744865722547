.searchResult {
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 5px;
  padding: 10px;

  &:hover {
    background: #eee;
    cursor: pointer;
  }
}

.paperSearch {
  &__result {
    width: 100%;
  }

  &__input {
    display: inline-flex;
    width: 100%;
  }
}

.loading-spinner {
  width: 100%;
  padding: 50px !important;

  &:hover {
    cursor: default;
  }
}

.no-results-alert {
  margin-top: 5px;
}
