.width80 {
  width: 80%;
  margin: auto;
}

.searchbar {
  display: flex;
  justify-content: space-between;
}

.signout {
  color: red;
}

.home-section {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  border-radius: 1em;
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 1em;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.35);
  }
}
