@import '../../styles/colors.scss';

.ant-tag.ant-tag {
  background-color: var(--color-primary-bg);
  border-color: var(--color-border);

  &:hover {
    background-color: var(--color-primary-bg)-darker;
    border-color: var(--color-primary);
  }

  margin: 3px;
}
