@import '../../styles/common.scss';

$default-padding: 30px;

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  .logo {
    @include green-filter;
    width: 30%;
    margin-bottom: 5%;
  }

  .product-title {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .demo {
      margin-bottom: 30px;
      width: 70%;
    }
  }

  .feature-list {
    text-align: left;
    list-style-image: url('./airplane.png');
    font-size: 1.5em;

    li {
      margin-bottom: $default-padding;
    }
  }

  &--actions {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    > p {
      margin: 0;
    }
  }
}

.modal-body {
  text-align: center;
  font-size: 1.5em;

  > a {
    width: 240px; // This is the width of the GoogleButton itself
    cursor: unset;

    > div {
      margin: 0 auto;
    }
  }
}
