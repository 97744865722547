@import '../../styles/colors.scss';

/* Colors */
$text-color: black;
$hover-accent: var(--color-primary);

.right {
  float: right;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.collapsed {
  flex-direction: column;
  height: 100% !important;
}

.menuHidden {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: height 0.25s ease-out;
}

.menuExpanded {
  display: flex;
  flex-direction: column;
  transition: height 0.25s ease-in;
}

.infoContent {
  width: 300px;
}

.menu {
  position: sticky;
  top: 0;
  z-index: 10;

  display: flex;
  color: $text-color;
  justify-content: space-between;
  align-content: center;
  list-style: none;
  width: 100%;
  background: white;
  box-shadow: 0 2px 4px 0 var(--color-primary-bg-dark);
  padding: 4px 12px 4px 12px;
  margin-block-end: 0px;

  margin-bottom: 1em;

  li {
    margin-left: 12px;
    line-height: 2;
  }

  a {
    font-weight: 700;
    font-size: 1rem;
    color: $text-color;
    float: left;
  }

  a:hover {
    color: $hover-accent;
  }

  .ant-btn {
    &-background-ghost {
      color: lightgray;
    }
    color: black;
    &:hover {
      color: $hover-accent;
    }
    &:active {
      color: $hover-accent;
    }
  }

  .ant-btn-default:hover {
    color: $hover-accent;
  }

  h5 {
    text-align: left;
    color: var(--color-primary);
  }

  .userIcon {
    color: var(--color-primary);
  }

  &__item > a > h5 {
    line-height: 1.7;
    text-align: left;
    margin-bottom: 0;
  }

  &__item {
    color: $text-color;
    font-weight: 700;
    font-size: 1rem;
    margin-left: 8px;
  }

  &__avatar {
    display: flex;
    align-items: center;
  }

  .menu__badge {
    box-shadow: 5px;
  }

  .menu__badge .ant-badge-count {
    background-color: lightgray;
    color: #222;
    opacity: 0.5;
  }
}

// Animation
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
