@import '../../styles/colors.scss';
  
.preview {
  width: 100%;
  max-height: 200px;
  overflow: hidden;

  mask-image: linear-gradient(180deg, white, transparent);

  &.expanded{    
    mask-image: none;
    max-height: 100%;
  }
}

.button-container{
  display: flex;
  justify-content: space-around;
}
