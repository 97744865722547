.reading-list {
  &__item {
    display: inline-flex;
    align-items: center;
    width: 100%;

    &-container {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: space-between;
      overflow: auto;
    }

    &-button {
      padding-bottom: 4px;
    }

    &-handle {
      font-size: 14pt;
      margin-bottom: 5px;
      margin-right: 15px;
    }
  }

  .empty-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.reading-list-adder {
  margin-top: 5px;

  &__startButton {
    float: right;
  }
  &__form {
    display: flex;
    justify-content: right;

    .ant-btn {
      margin-left: 5px;

      // override antd allowing disabled buttons to still have hover effects
      &[disabled] {
        &:hover {
          color: #999 !important;
          border: 1px solid #d9d9d9 !important;
          background: none;
          color: rgba(0, 0, 0, 0.25) !important;
        }
        background: none;
        cursor: not-allowed;
      }
    }
  }
}

// When dragging, these are outside the .reading-list parent class,
// so declare these styles here
.ant-list-item-meta-content {
  width: 100% !important;
}

.ant-list-item-meta-title {
  margin-right: 10px !important;
}

.ant-list-item {
  padding: 12px 24px !important;
}
