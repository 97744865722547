.themePicker {
  display: flex;
  width: 5rem;
  justify-content: space-between;
  align-items: center;

  .pickButton {
    height: 1rem;
    width: 1rem;

    &.isSelected {
      height: 1.25rem;
      width: 1.25rem;
    }

    border-radius: 50%;
    opacity: 0.75;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}
