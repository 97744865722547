.public-profile {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  padding: 10px;
  margin-top: 10px;

  .ant-card {
    margin-top: 0 !important;
  }
}
