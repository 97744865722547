@import '../../styles/colors.scss';

.review-modal {
  &__table {
    .ant-descriptions-item-label {
      font-weight: bold;
    }
  }

  .ant-alert-info {
    background-color: var(--color-primary-bg);
    border: 1px solid var(--color-primary-light);

    .ant-alert-icon {
      color: var(--color-primary);
    }
  }
}

// The provided modal header has 56px wide close icon which we want to avoid
.ant-modal-header {
  padding-right: 56px !important;
}

.modal-title {
  flex-flow: wrap;

  .modal-buttons {
    display: flex;

    & > * {
      // Don't add right-spacing to the rightmost element
      &:not(:last-child) {
        margin-right: 1em;
      }

      // Handles buttons within other elements (like <Links>)
      > .nested {
        width: 100%;
        margin-right: 0;
      }

      &.outline-red {
        border-color: red;
      }
    }

    @media only screen and (max-width: 650px) {
      flex-direction: column;

      // When stacked, all children should fill available width and have a slight gap
      > * {
        width: 100%;
        margin-top: 0.25em;
      }
    }
  }
}
