.ant-carousel {
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 5px;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 300px;
  line-height: 300px;
  background: lightgray;
  overflow: hidden;
}

.ant-carousel .slick-dots li button {
  background: lightgray;
}

.ant-carousel .slick-dots li.slick-active button {
  background: lightgray;
}
