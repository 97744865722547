@import '../../styles/colors.scss';

$dynamic-text-area-min-height: 80px;

.ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}

.paw-form {
  .error {
    color: darkred;
  }

  .react-datepicker__month-text--keyboard-selected {
    background: var(--color-primary) !important;
    &:hover {
      background: var(--color-primary-dark) !important;
    }
  }

  padding-bottom: 5vh;

  input {
    border: 1px solid var(--color-border);
    width: 90%;
    padding-left: 6px;
  }

  @mixin text-area-styles {
    border: 1px solid var(--color-border);
    width: 100%;
    padding-left: 6px;
    margin-bottom: 8px !important;
  }

  .preview-text-area {
    @include text-area-styles();
    outline: none !important;
    padding-top: 2px;
    min-height: $dynamic-text-area-min-height;
    border-radius: 6px;
  }

  textarea {
    @include text-area-styles();

    &:focus {
      outline: none !important;
      box-shadow: 0 0 0 1pt var(--color-primary);
    }
  }

  .ant-btn.dynamic-delete-button {
    cursor: pointer;
    border: none;
    box-shadow: none;
    font-size: 18px;
    color: #999;
    transition: all 0.3s;
    margin-left: 3px;
    padding: 0;

    &:hover {
      color: red !important;
    }

    &[disabled] {
      &:hover {
        color: #999 !important;
      }
      background: none;
      cursor: not-allowed;
    }
  }

  .ant-btn-primary {
    background: var(--color-primary) !important;
  }

  .bullet {
    margin-right: 20px;
  }

  .ant-legacy-form-item {
    margin-bottom: 2px !important;

    &-children {
      display: flex;
      align-items: center;
    }
  }

  label {
    font-weight: bold;
    margin-top: 15px;
  }
  .bullet-text-area {
    display: flex;
    width: 100%;

    .ant-input-textarea {
      width: 100%;
    }
  }

  .form-item {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker__input-container {
    width: inherit;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .form-item > input,
  .dynamic-field,
  .react-datepicker__input-container > input {
    width: 100%;
    height: 40px;
    border-radius: 6px;
  }

  .dynamic-text-area {
    min-height: $dynamic-text-area-min-height;
    border-radius: 6px;
  }

  .ant-row {
    margin-bottom: 50px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .plus-button {
    margin-top: 5px;
  }

  .dynamic-field-container {
    margin-bottom: 5px;
    &:last-of-type {
      margin-bottom: 0;
    }

    .field-with-delete-button {
      width: 100%;
      display: flex;
    }
  }
}
.latex-demo {
  textarea {
    border: 1px solid var(--color-border);
    width: 90%;
    padding-left: 6px;
    margin-bottom: 8px !important;
    border-radius: 6px;

    &:focus {
      outline: none !important;
      box-shadow: 0 0 0 1pt var(--color-primary);
    }
  }
}
